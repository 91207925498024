@import url('../../../assets/styles/variables.css');

.tabs {
  display: flex;
  gap: 30px;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
}

.tab {
  width: 90px;
  height: 8px;
  border-radius: 20px;
  background-color: #6F6F6F;
  opacity: 0.5;
}

.active {
  background-color: var(--primary-color);
  opacity: 1;
}