.wrapper {
  display: flex;
  flex-direction: column;
}

.detailCard {
  display: flex;
  justify-content: space-between;
}

.detailCardItem {
  border: 1px solid #EBEBEB;
  width: 100%;
}

.detailCardItem h1 {
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #EBEBEB;
  width: 100%;
  text-align: center;  
  margin: 20px 0;
  min-height: 70px;
  font-size: 12px;
}

.detailCardItem p {
  padding: 10px;
  text-align: center;
}

.normalItemsRow, .uniqueRow {
  display: flex;
  justify-content: space-between;
}

.detailCardRight {
  width: 100%;
  display: flex;
}

.detailCardRightContent.fullWidth {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.editIcon {
  cursor: pointer;
  float: right;
}

.uniqueRow h1 {
  border-right: 1px solid transparent;
  margin: 0;
  padding: 20px;
  font-size: 12px;
}

.editContainer {
  display: flex;
  justify-content: center;
}

.uniqueRow .editContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 15px;
}

.uniqueRow .editContainer img {
 width: 20px;
}

.containerModal h2 {
  font-size: 18px;
}

.containerModal img {
  display: block;
  margin: 20px auto;
}

.buttonsContainer {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.buttonSuccess {
  padding: 10px 40px;
  background: var(--success-color);
  color: var(--white-color);
  border-radius: 5px;
  border: none;
  margin-right: 20px;
  cursor: pointer;
}

.buttonError {
  padding: 10px 40px;
  background: var(--primary-color);
  color: var(--white-color);
  border-radius: 5px;
  border: none;
  cursor: pointer;
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

@media screen and (max-width: 1400px) {
  .detailCardItem h1 {
    text-align: center;
    max-width: 100px;
    font-size: 12px;
    min-height: 60px;
    margin: 10px auto;
  }
}

