@import url('../../../assets/styles/variables.css');

.leftContainer {
  background: var(--primary-color);
  min-width: 50vw;
  min-height: 100%;
}

.rightContainer {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  background: var(--white-color);
  width: calc(50vw - 60px);
  max-width: 100%;
  padding: 0 50px 20px 50px;
}

.titleContainer {
  display: flex;
  flex-direction: column;
}

.titleContainer h1 {
  color: var(--primary-color);
  font-size: 30px;
  margin: 0 0 10px 0;
}

.titleContainer h2 {
  color: var(--black-color);
  font-size: 18px;
  font-weight: normal;
  margin: 0;
}

.tabsContainer {
  margin-bottom: 0;
}


.loginContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
  min-height: 100%;
}

.cardForm {
  background: var(--white-color);
  border: 1px solid var(--athens-gray-color);
  box-sizing: border-box;
  border-radius: 8px;
  padding: 40px;
  width: 380px;
  height: 500px;
  padding-bottom: 10px;
}

.formGroup {
  margin: 0;
}

.formItem>* {
  display: flex;
}

label {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.3px;
  color: var(--black-color);
  width: 100%;
}

input {
  margin-top: 5px;
  background: var(--white-gray);
  border: 1px solid var(--black-color);
  box-sizing: border-box;
  border-radius: 30px;
  padding: 11px;
  width: 100%;
  height: 50px;
}

.cardFormHeader {
  text-align: center;
}

.cardFormHeader img {
  max-width: 190px;
}

.textInfo {
  font-size: 20px;
  color: #11183F;
}

.btnSubmit {
  width: 100%;
  border: none;
  background: var(--success-color);
  border-radius: 30px;
  color: #fff;
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.2px;
  padding: 10px 24px;
  animation: all ease-in-out .5s;
  cursor: pointer;
}

.btnSubmit:hover {
  opacity: 0.9;
}

.errorMessage {
  color: #f04c4c;
  font-weight: bold;
  text-align: center;
  padding: 10px 0;
}

.lockMessage {
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 0;
  color: var(--success-color);
  text-align: center;
  padding: 10px 0;
}

.lockMessage i {
  margin-right: 5px;
}

.lockMessage i  svg {
  width: 20px;
  height: 20px;
}

.passContainer {
  display: flex;
  align-items: center;
}

.passType {
  background: none;
  border: none;
  transform: translate(-10px, 53px);
  cursor: pointer;
  z-index: 5;
  line-height: 0; 
  margin-left: auto;
  margin-right: 0;
}

.passType svg {
  margin-top: 5px;
  width: 15px;
  height: 15px;
  fill: var(--waterloo-color);
}

.inputsContainer {
  margin-top: 20px;
  margin-bottom: 45px;
}

.link {
  display: block;
  margin: 0 auto;
  color: #000;
  text-decoration: underline;
  font-size: 12px;
  margin-top: 30px;
  cursor: pointer;
}

.modalContainer button {
  margin: 30px auto;
}