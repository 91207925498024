@import url('../../../../assets/styles/variables.css');

.tableContainer {
  margin: 40px 55px;
  background-color: var(--white-color);
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  border-radius: 15px;
}

.tableContainer thead tr {
  grid-template-columns: repeat(5, 1fr);
}

.tableContainer tbody tr {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  align-items: center;
  padding: 4px 0;
  background: var(--white-color);
}

.tableContainer tbody td {
  text-align: center;
  border-right: 1px solid #ddd;
}

.tableContainer thead tr th {
  font-size: 13px;
  background: var(--white-color);
  border-bottom: 1px solid #ddd;
  border-right: 1px solid #ddd;
}

.customTable {
  width: 100%;
  border-collapse: separate;
  font-size: 0.9em;
  border: 1px solid #ccc;
  border-radius: 15px;
  overflow: hidden;
}

.customTable th, .customTable td {
  padding: 12px 15px;
}

.customTable th {
  background-color: #f8f8f8;
  color: #333;
}

.customTable th:last-child, .customTable td:last-child {
  border-right: none;
}

.customTable tr:last-child td {
  border-bottom: none;
}

.profileButton {
  border: none;
  background: none;
  cursor: pointer;
}

.profileButton img {
  width: 24px;
}
