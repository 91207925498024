@import url('../../assets/styles/variables.css');

.btn {
  background-color: #188FD7;
  border: none;
  border-radius: 5px;
  color: var(--white-color);
  font-size: 1em;
  font-family: var(--secondary-font);
  height: 45px;
  width: 245px;
  padding: 0 50px;
  outline: none;
  cursor: pointer;
}

.btn:hover {
  opacity: 0.8;
}

.white {
  background: var(--white-color);
  color: var(--portal-primary-color);
}

.dark {
  background: var(--portal-secondary-color);
  color: var(--white-color);
}