@import url('../../../assets/styles/variables.css');

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 20px 0 20px;
  margin: 0;
}

.icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 48px;
  color: var(--primary-color);
  margin-bottom: 10px;
}

.icon h2 {
  font-size: 25px;
  color: var(--primary-color);
  margin: 0;
}

.icon img {
  max-width: 80px;
}

.message {
  font-size: 12px;
  margin-bottom: 20px;
}

.errorMessage {
  text-align: center;
  margin-bottom: 20px;
  color: var(--new-dashboard-red);
}

.inputWrapper {
  font-size: 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  margin-bottom: 20px;
  width: 100%;;
}

input {
  width: 100%;
  height: 40px;
  border: 1px solid var(--new-dashboard-gray);
  border-radius: 10px;
  background-color: var(--white-color);
}

.timer {
  font-size: 14px;
  margin: 10px 0 25px 0;
  color: var(--new-dashboard-gray);
}

.button {
  background-color: gray;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.button:hover {
  background-color: var(--dashboard-green-font);
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.button:disabled {
  background-color: #ccc;
}

.valid {
  color: var(--dashboard-green-font);
}

.invalid {
  color: var(--new-dashboard-red);
}

.inputWrapperItem {
  display: flex;
  flex-direction: column;
  width: 100%;
}

p {
  font-size: 12px;
  color: var(--secondary-black-color);
  margin: 0 0 5px 0;
}

p:first-child {
  margin: 10px 0;
}

p:last-child {
  margin-bottom: 20px;
}