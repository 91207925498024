@import url('../../assets/styles/variables.css');

.statsTable {
  width: 100%;
  margin: 40px 0;
  display: grid;
  grid-template-columns: 1fr;
}

.statsRow, .statsRowHeader, .totalData {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  text-align: center;
  margin: 2px 0;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
}

.totalData {
  background-color: #fff;
  color: 000;
}

.statsCell {
  font-size: 14px;
  padding: 20px;
}

.statsRowHeader {
  background-color: #e0e0e0;
  font-weight: bold;
  text-align: center;
  padding: 20px 0;
}

.statsRow:last-child .statsCell {
  border-bottom: none;
}