@import url('../../assets/styles/variables.css');

.cardContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  background-color: var(--white-color);
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.noPadding {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  background-color: var(--white-color);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.cardItem {
  text-align: center;
  border-right: 1px solid #F9F9F9;
  padding: 10px;
}

.cardItem:last-child {
  border-right: none;
}

.cardItem h1 {
  font-size: 50px;
  color: #464A53;
  margin: 0;
}

.cardItem p {
  margin-top: 10px;
  color: #464A53;
  font-size: 15px;
}

/* Icon type */

.iconCard {
  display: flex;
  justify-content: space-around;
}

.iconCard .cardItem {
  width: 100%;
}

.iconCard h1 {
  font-size: 16px;
}


.content {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 30px;
  width: 100%;
}

.iconContainer img {
  width: 70px;
  height: 70px;
  margin-right: 30px;
}

.subItemsContainer {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.subItemsContainer .itemContent {
  display: flex;
  align-items: center;
}

.subItemsContainer .itemContent h2 {
  font-size: 18px;
  font-weight: normal;
  margin-right: 20px;
}

.subItemsContainer .itemContent p {
  display: block;
  margin-top: 10px;
  font-size: 40px;
  font-weight: bold;
}
