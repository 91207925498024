@import url('../../../../assets/styles/variables.css');

.profileCard {
  display: flex;
}

.profileCardLeft {
  width: 60%;
}

.profileCardLeft img{
  opacity: .5;
}

.profileCardRight {
  width: 40%;
  border-left: 1px solid #F9F9F9;
  padding-left: 30px;
}

.profileCardLeftContent, .profileCardRightContent, .statusActive, .statusInactive  {
  display: flex;
  align-items: flex-start;
}

.profileCardLeftContent p, .profileCardRightContent p, .statusActive p, .statusInactive p {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-size: 10px;
  color: #828282;
  margin: 0 20px 20px 0;
}

.profileCardLeftContent p span, .profileCardRightContent p span, .statusActive p span, .statusInactive p span {
  font-weight: bold;
  color: #120638;
  margin-bottom: 5px;
}

.profileCardRightContent p, .statusActive p, .statusInactive p {
  font-size: 12px;
}

.profileCardLeftInfos {
  display: flex;
  flex-direction: column;
}

.profileCardLeftContent {
  display: flex;
}

.subtitle h3 {
  font-size: 15px;
  margin-bottom: 10px;
  color: var(--primary-color);
}

.subtitle img {
  margin: 0 !important;
}

.statusActive p {
  color: var(--success-color);
}

.statusInactive p {
  color: red;
}

.generalButton {
  width: 100%;
  white-space: nowrap;
  cursor: pointer;
  font-size: 12px;
  border-radius: 5px;
  padding: 15px 10px;
  border: none;
  height: auto;
  display: inline;
  background: #188FD7;
  margin: 20px 0 0 0;
  cursor: pointer;
  color: #fff;
}

.buttonsContainer {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.buttonSuccess {
  padding: 10px 40px;
  background: var(--success-color);
  color: var(--white-color);
  border-radius: 5px;
  border: none;
  margin-right: 20px;
  cursor: pointer;
}

.buttonError {
  padding: 10px 40px;
  background: var(--primary-color);
  color: var(--white-color);
  border-radius: 5px;
  border: none;
  cursor: pointer;
}

.modalItem {
  display: flex;
  flex-direction: column;
  /* width: 100%; */
  min-width: 600px;
  margin: 30px 0;
}

.modalItem label {
  font-size: 15px;
  margin-bottom: 10px;
}

.modalItem input, .modalItem select {
  display: block;
  width: 100% !important;
  height: 50px !important;
  border-radius: 5px !important;
}

.containerModal img {
  display: block;
  margin: 0 auto;
}

.editIcon {
  cursor: pointer;
  float: right;
  width: 20px;
  height: 20px;
  margin-left: 10px;
}

.gender {
  flex-direction: row !important;
}

.genderContent {
  display: flex;
  flex-direction: column;
}

.editContainer {
  display: flex;
  align-items: center;
}

select {
  display: block;
  width: 100% !important;
  height: 50px !important;
  border-radius: 5px !important;
}