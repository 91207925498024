@import url('../../assets/styles/variables.css');

.wrapper {

}

.wrapper h3 {
  font-size: 18px;
}

.top {
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid var(--portal-secondary-light-color);
}

.wrapper h2 {
  font-size: 20px;
  color: var(--primary-color);
  margin-bottom: 30px;
}

.wrapper h3 {
  font-size: 18px;
  color: var(--primary-color);
}

.radioItem {
  display: flex;
  align-items: center;
  padding: 5px 5px 5px 0;
}

.radioItem * {
  font-family: var(--primary-font);
  text-transform: none;
}

.radioItem input {
  width: 15px;
  height: 15px;
  padding: 10px;
}

.radioItem label {
  display: flex;
  align-items: center;
  font-size: 13px;
  color: var(--bunting-color);
}

.radioItem span {
  font-size: 12px;
}

.radioTexts {
  display: flex;
  flex-direction: column;  
  margin-left: 5px;
}

.radioTextsParcelado {
  margin-left: 15px;
}

.radioTextsParcelado label {
  display: block;
}

.radioTextsParcelado label b {
  display: block;
  margin: 10px 0 0 0;
}

.dueDate .radioTexts {
  justify-content: center;
}

.dueDate .radioItem {
  border: none;
  padding: 20px 20px 20px 0;
}

.generalButton {
  width: 100%;
  white-space: nowrap;
  cursor: pointer;
  font-size: 12px;
  border-radius: 5px;
  padding: 15px 10px;
  border: none;
  height: auto;
  display: inline;
  background: #C5C5C5;
  margin: 20px 0 0 0;
  cursor: pointer;
  color: #fff;
}

.generalButton:hover {
  background: #46B700;
}

.close {
  display: flex;
  justify-content: center;
  cursor: pointer;
  border: none;
  padding: 0;
  width: 26px;
  height: 26px;
  border-radius: 5px;
  background: var(--portal-secondary-light-color);
}

.close svg {
  fill: var(--portal-dark-gray-color);
  width: 25px;
  height: 25px;
}

.container {
  display: flex;
}

.customCheckbox {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
  width: 16px;
  height: 16px;
  border: 1px solid #666666;
  border-radius: 50%;
  background: white;
  cursor: pointer;
  position: relative;
}

.customCheckbox:checked {
  background-color: #f3f3f377;
}

.customCheckbox:checked::after {
  content: '';
  position: absolute;
  top: 4px; 
  left: 4px; 
  width: 12px; 
  height: 12px; 
  border-radius: 50%;
  background: var(--primary-color);
}

.radioTexts label {
  margin-left: 0.5em;
  vertical-align: middle;
}

.radiosContainer {
  border-radius: 8px;
  border: 1px solid #CDCDCD;
  padding: 20px;
  max-height: 220px;
  overflow-y: auto;
}

.subContainer {
  width: 100%;  
  margin: 0 8px;
}

::-webkit-scrollbar {
  width: 6px;
  height: 12px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}
