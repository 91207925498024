body {
  margin: 0;
}

* {
  font-family: 'Roboto', sans-serif;
}

.anim-appear {
  opacity: 0.01;
}

.anim-appear.anim-appear-active {
  opacity: 1;
  transition: opacity .5s ease-in;
}

.modal-open {
  overflow: hidden;
}


/* react-datepicker */
.react-datepicker__header {
  background-color: var(--white-color) !important;
  border: none !important;
}

.react-datepicker__day-names {
  display: none;
}

.react-datepicker__week {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.react-datepicker__header::after {
  content: "\00a0\00a0\00a0 S\00a0\00a0\00a0\00a0\00a0\00a0\00a0 T\00a0\00a0\00a0\00a0\00a0\00a0\00a0 Q\00a0\00a0\00a0\00a0\00a0\00a0 Q\00a0\00a0\00a0\00a0\00a0\00a0 S\00a0\00a0\00a0\00a0\00a0\00a0\00a0 S\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0 D";
  width: 100%;
  min-width: 100%;
  display: flex;
  justify-content: space-between;
  color: var(--portal-gray-color);
  margin: 0;
  padding: 0 10px;
  font-size: 12px;
}

.timePicker .react-datepicker__header {
  display: none !important;
}

.react-datepicker-wrapper {
  display: flex;
  width: 100%;
}

.react-datepicker__day--in-range {
  background: var(--primary-color) !important;
}

.react-datepicker__month-text--selected {
  background: var(--primary-color) !important;
}

.react-datepicker__day--selected:hover {
  background: var(--primary-color) !important;
}

.react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range {
  background: var(--primary-color) !important;
}