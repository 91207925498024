@import url('../../../../assets/styles/variables.css');

.historyCard {
  display: flex;
  flex-direction: column;
}

.historyCardTop {
  display: flex;
  align-items: center;
  width: 100%;
  background: var(--primary-color);
  color: var(--white-color);
  padding: 20px 0 20px 20px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  box-sizing: border-box;
}

.buttonContainer {
  margin: 0 0 0 auto;
}

.buttonContainer button {
  background: var(--white-color);
  color: var(--primary-color);
  border: none;
  font-family: var(--primary-font);
  padding: 10px;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  cursor: pointer;
  transition: background 0.3s ease;
  font-weight: bold;
}

.buttonContainer button:hover {
  background: var(--primary-color);
  color: var(--white-color);
  border: 1px solid var(--white-color)
}

.historyCardLeftInfos {
  display: flex;
  flex-direction: column;
}

.historyCardLeftTop {
  background-color: var(--primary-color);
}

.historyCardLeftContent {
  display: flex;
}

.subtitle h3 {
  font-size: 15px;
  margin-bottom: 10px;
  color: var(--white-color);
}

.details {
  display: flex;
  align-items: center;
  font-size: 10px;
  margin: 10px 0;
}

.details img {
  margin-right: 5px;
}

.marginLeft {
  margin-left: 10px;
}

.historyCardRightContent {
  padding: 20px;
}

.historyCardRightContent p {
  font-size: 10px;
  font-weight: bold;
}

.historyCardRightContent span {
  font-size: 10px;
  font-weight: normal;
}