@import url('../../../assets/styles/variables.css');

.container {
  font-family: var(--new-dashboard-primary-font);
  padding: 40px 40px 40px 60px;
}

.titlePastDueDash,
.subtitlePastDueDash {
  font-family: var(--new-dashboard-primary-font);
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.3px;
  color: #757A96;
}

.titlePastDueDash {
  font-size: 24px;
  line-height: 30px;
}

.subtitlePastDueDash {
  font-family: var(--new-dashboard-primary-font);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
}

.subtitlePastDueDashCustomer {
  font-size: 30px;
  font-weight: bold;
  color: var(--indigo-color);
  margin: 0;
}

.customerName {
  color: #11183F;
  font-weight: 600;
}

.card, .cardGreen {
  font-family: var(--new-dashboard-primary-font);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-height: 150px;

  background: #FFFFFF;
  border: 1px solid #DFE0EB;
  box-sizing: border-box;
  border-radius: 8px;
  width: 100%;
  margin: 15px;

  padding: 25px 0;
}

.card > *, .cardGreen > * {
  margin: 0;
  padding: 0;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
}

.card h4 {
  font-family: var(--new-dashboard-primary-font);
  margin: 0;
}

.card h2 {
  font-family: var(--new-dashboard-primary-font);
  margin: 0;
  font-size: 32px;
}

.card i {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 75px;
  height: 75px;
  background: #F0F0F0;
  border-radius: 8px;
  margin-right: 20px;
}

.card i svg {
  width: 24px !important;
  height: 24px !important;
}

.contentFlex {
  display: flex;
  justify-content: space-around;
}

.contentFlex1 {
  display: flex;
  justify-content: space-around;
}

.contentFlex1 > .infoCustomer {
 width: calc(100% - 120px);
}

.infoCustomer h5 {
  font-size: 16px;
  color: var(--bunting-color);
  margin-top: 10px;
  font-weight: normal;
}

.contentFlex1 > .card {
  width: calc(100vw / 3);
 }
 

.amountTotal {
  font-size: 30px;
  padding-top: 5px;
  color: #11183F;
}

.amountTotalGreen {
  padding-top: 5px;
  color: #03942C;
}

.infoCustomer {
  box-sizing: border-box;
  padding: 10px;
}

.containerCards {
  display: flex;
}

.cardGreen {
  background: #4ECB71;
}

.cardGreen h2, .cardGreen h4 {
  color: #fff;
}

.graphics {
  margin-top: 90px;
}

.graphicItem {
  display: flex;
}

.graphicItem .graphicItemContent {
  width: 85%;
}

.graphicItem .graphicItemCard {
  width: 15%;
}

.graphicItem .graphicItemContent span {
  display: block;
  font-size: 20px;
  font-weight: 600;
  color: var(--waterloo-color);
  margin-bottom: 10px;
}

.graphicItem:nth-child(2) {
  margin-top: 80px;
}

.graphicItemCard {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.graphicCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 65px;

  background: #FFFFFF;
  border: 1px solid #DFE0EB;
  box-sizing: border-box;
  border-radius: 8px;
  width: 140px;
  text-align: center;
}

.graphicCardTitle {
  font-family: 'Quicksand';
  color: #757A96;
  font-size: 16px;
  margin: 6px 0;
}

.graphicCardNumber {
  font-size: 20px;
  font-weight: 600;
  color: #03942C;
  margin-bottom: 5px;
}

.brownContainer h4, .greenContainer h4 {
  line-height: 16px;
}

.brownContainer h2 {
  border-radius: 8px;
  padding: 5px 20px;
  font-size: 30px;
  font-weight: 500;
  background: var(--dashboard-brown-background);
  color: var(--dashboard-brown-font);
}

.greenContainer h2 {
  border-radius: 8px;
  padding: 5px 20px;
  font-size: 30px;
  font-weight: 500;
  background: var(--dashboard-green-background);
  color: var(--dashboard-green-font);
}

.blueContainer h2 {
  border-radius: 8px;
  padding: 5px 20px;
  font-size: 30px;
  font-weight: 500;
  background: var(--dashboard-blue-background);
  color: var(--dashboard-blue-font);
}

.yellowContainer h2 {
  border-radius: 8px;
  padding: 5px 20px;
  font-size: 30px;
  font-weight: 500;
  background: var(--dashboard-yellow-background);
  color: var(--dashboard-yellow-font);
}

.redContainer h2 {
  border-radius: 8px;
  padding: 5px 20px;
  font-size: 30px;
  font-weight: 500;
  background: var(--dashboard-red-background);
  color: var(--dashboard-red-font);
}

.top {
  height: 130px;
  background: #334588;
  padding: 40px 20px 30px 60px;
}

.top h3 {
  color: var(--white-color);
  font-size: 36px;
  font-family: var(--new-dashboard-primary-font);
}

.cardsWrapper h3 {
  color: var(--dash-sidebar-secondary);
  font-size: 20px;
  font-weight: 700;
  padding: 0 0 0 20px;
  margin: 40px 0 0 0;
}

.card div h2:last-of-type {
  display: inline;
}

/* Charts */
.charts {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  flex-wrap: nowrap;
}

.charts > div {
  width: 100%;
}

.charts > div:first-child {
  margin-right: 15px !important;
}

.containerFilters {
  display: flex;
  justify-content: flex-end;
}

.containerTop {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.containerTop h3 {
  font-family: var(--new-dashboard-primary-font);
  margin: 0;
}

.generalButton button {
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  min-width: 90px;
  max-width: 150px;
  cursor: pointer;
  font-size: 12px;
  border-radius: 5px;
  padding: 10px 10px;
  border: none;
  height: auto;
  background: var(--portal-secondary-color);
  margin: 0 10px 0 10px;
}

.buttonContainer{
  display: flex;
  justify-content: flex-end;
  margin-bottom: 30px;
}

@media (max-width: 1700px) {
  .charts {
    flex-wrap: wrap;
  }
  .card, .cardGreen {
    flex-direction: column;
    text-align: center;
    align-items: center;
  }

  .card > div {
    margin-top: 10px;
    flex-direction: column;
  }

}