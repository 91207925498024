.profileCard {
  display: flex;
}

.profileCardLeft {
  display: flex;
  align-items: center;
  width: 100%;  
  border-bottom: 1px solid #eee;
  padding-bottom: 20px;
}

.profileCardLeftTop {
  display: flex;
  align-items: center;
}

.profileCardLeft img {
  opacity: .5;
  margin-right: 40px;
}

.profileCardRight {
  width: 40%;
  border-left: 1px solid #F9F9F9;
  padding-left: 30px;
}

.profileCardLeftContent, .profileCardRightContent, .statusActive, .statusInactive  {
  display: flex;
  align-items: flex-start;
}

.profileCardLeftContent p, .profileCardRightContent p, .statusActive p, .statusInactive p {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-size: 10px;
  color: #828282;
  margin: 0 20px 20px 0;
}

.profileCardLeftContent p span, .profileCardRightContent p span, .statusActive p span, .statusInactive p span {
  font-weight: bold;
  color: #120638;
  margin-bottom: 5px;
}

.profileCardRightContent p, .statusActive p, .statusInactive p {
  font-size: 12px;
}

.profileCardLeftInfos {
  display: flex;
  flex-direction: column;
}

.profileCardLeftContent {
  display: flex;
}

.subtitle h3 {
  font-size: 15px;
  margin-bottom: 10px;
  color: var(--primary-color);
}

.subtitle img {
  margin: 0 !important;
}

.statusActive {
  color: var(--success-color) !important;
}

.statusInactive {
  color: var(--primary-color) !important;
}

.generalButton {
  width: 100%;
  white-space: nowrap;
  cursor: pointer;
  font-size: 12px;
  border-radius: 5px;
  padding: 15px 10px;
  border: none;
  height: auto;
  display: inline;
  background: #188FD7;
  margin: 20px 10px 0 10px;
  cursor: pointer;
  color: #fff;
}

.buttonsContainer {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.buttonSuccess {
  padding: 10px 40px;
  background: var(--success-color);
  color: var(--white-color);
  border-radius: 5px;
  border: none;
  margin-right: 20px;
  cursor: pointer;
}

.buttonError {
  padding: 10px 40px;
  background: var(--primary-color);
  color: var(--white-color);
  border-radius: 5px;
  border: none;
  cursor: pointer;
}

.modalItem {
  display: flex;
  flex-direction: column;
  /* width: 100%; */
  min-width: 600px;
  margin: 30px 0;
}

.modalItem label {
  font-size: 15px;
  margin-bottom: 10px;
}

.modalItem input, .modalItem select {
  display: block;
  width: 100% !important;
  height: 50px !important;
  border-radius: 5px !important;
}

.containerModal img {
  display: block;
  margin: 0 auto;
}

.historyCardTop {
  margin-bottom: 40px;
}

.historyContainer {
  margin: 0 40px;
}

.details {
  display: flex;
  align-items: center;
  padding: 10px;
  width: 120px;
  color: var(--white-color);
  background: var(--primary-color);
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.details img {
  margin-right: 5px;
}

.details span {
  font-size: 10px;
  margin-right: 5px;
}

.results {
  margin: 40px 0;
}

.historyItem {
  margin-bottom: 30px;
}

.historyItemTop {
  margin-bottom: 30px;
}

.historyItemTop p b {
  font-size: 20px;
}

.link {
  cursor: pointer;
  color: var(--primary-color);
  text-decoration: underline;
}

.containerButton {
  display: flex;
  justify-content: flex-end;
  width: 50%;
}

.containerButton button {
  max-width: 200px;
}

.dateContainer{
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 999;
  position: absolute;
  left: auto;
  right: auto;
  top: 30%;
}

.datepickerWrapper {
  padding: 20px 40px;
}

.filter {
  width: 100%;
  border: none;
  background: var(--success-color);
  border-radius: 5px;
  color: #fff;
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.2px;
  padding: 5px 15px;
  animation: all ease-in-out .5s;
  cursor: pointer;
  margin: 0;
}

.filter:hover {
  opacity: 0.9;
}

.datepickerWrapper > div {
  width: 100%;
  margin-bottom: 20px;
}