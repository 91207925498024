@import url('../../assets/styles/variables.css');

.wrapper {
  display: flex;
  justify-content: space-between;
  height: 100px;
  background: var(--white-color);
  padding: 20px 40px;
}

.wrapper h3 {
  color: var(--primary-color);
  font-size: 25px;
  font-weight: bold;
  margin: 0 0 10px 0;
}

.wrapper button {
  color: var(--primary-color);
  font-size: 12px;
  font-weight: bold;
  margin: 0 0 10px 0;
  background: none;
  border: none;
  cursor: pointer;
}

.left, .right {
  display: flex;
  align-items: center;
}

.wrapper h4 {
  color: var(--white-color);
  font-size: 12px;
  font-weight: normal;
  font-family: var(--new-dashboard-primary-font);
  margin: 0;
}

.left img {
  width: 35px;
  height: 15px;
  margin-right: 20px;
  margin-bottom: 10px;
}

.right img {
  width: 25px;
  height: 30px;
  margin-bottom: 5px;
}