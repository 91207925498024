.radioGroup {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.radioLabel {
  display: flex;
  align-items: center;
  cursor: not-allowed;
  margin-right: 10px;
  margin-top: 10px;
  font-size: 10px;
}

.radioLabel input[type="radio"] {
  appearance: none;
  width: 5px;
  height: 5px;
  padding: 7px;
  border: 1px solid #555;
  border-radius: 50%;
  outline: none;
  box-shadow: 0 0 5px #666666;
  transition: 0.2s;
  margin: 0 5px 0 0;
}

.radioLabel input[type="radio"]:disabled {
  box-shadow: 0 0 5px #ccc;
}

.radioLabel input[type="radio"]:disabled:checked {
  background: var(--primary-color);
}
