@import url('../../../assets/styles/variables.css');

.profileCard {
  display: flex;
}

.profileCardLeftInfos {
  display: flex;
  flex-direction: column;
}

.profileCardLeftContent {
  display: flex;
}

.subtitle h3 {
  font-size: 15px;
  margin-bottom: 10px;
  color: var(--primary-color);
}

.topCardsContainerTop {
  display: flex;
  justify-content: space-between;
  margin: 40px;
}

.cardContainer {
  margin: 40px;
}