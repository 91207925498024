@import url('../../../assets/styles/variables.css');

.titlePastDueDash,
.subtitlePastDueDash {
  font-family: var(--new-dashboard-primary-font);
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.3px;
  color: var(--new-dashboard-gray);
}

.titlePastDueDash {
  font-size: 24px;
  line-height: 30px;
}

.subtitlePastDueDash {
  font-family: var(--new-dashboard-primary-font);
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
}

.customerName {
  color: #11183F;
  font-weight: 600;
}

.uploadDocument {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  background: #FFFFFF;
  border: 1px solid #DFE0EB;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 0;
}

.uploadDocument > div {
  flex: 1;
  margin: 0 20px;
}

@media screen and (max-width: 1420px) {
  .uploadDocumentContent {
    flex-direction: column-reverse;    
  }
}

.uploadDocumentTitle {
  font-family: var(--new-dashboard-primary-font);
  text-align: center;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 700;
  font-size: 0.9em;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: #252733;
}

.uploadDocumentContent {
  display: flex;
  padding: 20px;
  background: var(--white-color);
  border-radius: 8px;
}

.subtitlePastDueDashCustomer {
  font-size: 30px;
  font-weight: bold;
  color: var(--new-dashboard-primary);
  margin: 0;
}

.infoCustomer h5 {
  font-family: var(--new-dashboard-primary-font);
  font-size: 16px;
  color: var(--bunting-color);
  margin-top: 10px;
  font-weight: 500;
}


h5 a {
  font-family: var(--new-dashboard-primary-font);
  font-size: 16px;
  color: var(--new-dashboard-primary);
  text-decoration: none;
}

.paddingContent {
  padding: 40px 40px 40px 60px; 
}

.smallMargin {
  margin-bottom: 20px;
}

.imageContainer {
  width: 33.33%;
}

.imageContainer img {
  width: 350px;
  height: 300px;
}

/* Health */

.subtitle {
  padding: 45px;
}

.subtitle h3 {
  color: var(--primary-color);
}

.subtitle p {
  margin-top: 30px;
  margin-bottom: 30px;
  font-size: 15px;
}