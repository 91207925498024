@import url('../../../assets/styles/variables.css');

.container {
  padding: 0 45px;
}

.containerTop h3 {
  margin-top: 40px;
}

.filtersContainer {
  display: flex;
  flex-direction: column;
}

.filtersContent {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 20px;
}

.filtersContent > * {
  flex-grow: 1;
  margin-right: 15px;
}

.subContainer {
  display: flex;
  align-items: flex-end;
  width: 100%;
  max-width: 350px;
}

.subContainer > * {
  margin-right: 10px;
}

.generalButton button {
  width: 100%;
  white-space: nowrap;
  cursor: pointer;
  font-size: 12px;
  border-radius: 5px;
  padding: 15px 10px;
  border: none;
  height: auto;
  display: inline;
  background: var(--portal-secondary-color);
  margin: 20px 10px 0 10px;
  cursor: pointer;
  color: #fff;
  min-width: auto !important;
}

.pastDuesContainer {
  margin-top: 50px;
}

.pastDuesContainer h3 {
  margin-bottom: 0;
}

.empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
}

.empty img {
  width: 150px;
  height: 150px;
  margin-left: 50px;
}

.empty h3 {
  margin-top: 30px;
  margin-bottom: 15px;
  font-size: 20px;
  font-weight: bold;
  color: var(--primary-color);
}

.empty p {
  text-align: center;
  max-width: 500px;
  font-size: 12px;
}

.close {
  background-color: transparent;
  outline: none;
  width: 10px;
  height: 10px;
  border: none;
  right: calc(-100% + 30px);
  cursor: pointer;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  border: 1px solid black;
  border-radius: 20px;
  padding: 11px;
}

.close::after,
.close::before {
  content: ' ';
  position: absolute;
  width: 1.5px;
  height: 10px;
  background-color: #000;
}

.close::after {
  transform: rotate(-45deg);
}

.close::before {
  transform: rotate(45deg);
}

/* health */
.subtitle h3 {
  color: var(--primary-color);
}

.subtitle p {
  margin-top: 30px;
  margin-bottom: 30px;
  font-size: 15px;
}

.filtersContainer {
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  border-radius: 10px;
  margin: 0 50px;
  padding: 15px;
  background: var(--white-color);
}

.searchContainer .container .containerTop input {
  margin-top: 10px;
  border: 1px solid #828282;
}

.searchContainer .container .containerTop label {
  color: #828282;
}

.searchContainer .container .containerTop button {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #828282;
  border-radius: 30px;
  width: 130px;
}

.searchContainer .container .containerTop button:hover {
  background: #46B700;
}

.searchContainer .container .containerTop button img {
  margin-left: 10px;
}