@import url('../../../assets/styles/variables.css');

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 20px 0 20px;
  margin: 0;
}

.icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 48px;
  color: var(--primary-color);
  margin-bottom: 20px;
}

.icon h2 {
  font-size: 25px;
  color: var(--new-dashboard-gray-dark);
}

.icon img {
  max-width: 80px;
}

.message {
  text-align: center;
  margin-bottom: 20px;
}

.errorMessage {
  text-align: center;
  margin-bottom: 20px;
  color: var(--new-dashboard-red);
}

.inputWrapper {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.input {
  width: 35px;
  height: 35px;
  text-align: center;
  font-size: 18px;
  border: 1px solid var(--new-dashboard-gray);
  border-radius: 10px;
  background-color: var(--white-color);
}

.timer {
  font-size: 14px;
  margin: 10px 0 25px 0;
  color: var(--new-dashboard-gray);
}

.button {
  background-color: gray;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.button:hover {
  background-color: var(--dashboard-green-font);
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.button:disabled {
  background-color: #ccc;
}
