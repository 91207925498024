@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;1,300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;1,400&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Montserrat&family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap');

:root {
  --athens-gray-color: #DFE0EB;
  --black-color: #000000;
  --secondary-black-color: #474747;
  --bright-turquoise-color: #13CAE6;
  --bunting-color: #11183F;
  --catskill-white-color: #EEF2F6;
  --gray-90-color: #e5e5e5;
  --emerald-color: #4ECB71;
  --indigo-color: #5668D1;
  --red-color: #FC1100;
  --white-color: #FFFFFF;
  --black-color: #000000;
  --waterloo-color: #757A96;
  --primary-color: #B41411; 
  --secondary-color: #88F4FF;
  --tertiary-color: #88F4FF;
  --success-color: #46B700;
  --dark-green: #2C5D59;
  --light-gray: #f3f3f3;
  --dark-gray: #43434E;

  --primary-font: 'Inter', sans-serif;
  --secondary-font: 'Inter', sans-serif;

  --dashboard-brown-font: #7D3300;
  --dashboard-brown-background: #FDE3D1;
  --dashboard-green-font: #2F7D00;
  --dashboard-green-background: #E2FDD1;
  --dashboard-blue-font: #00577D;
  --dashboard-blue-background: #D1E3FD;
  --dashboard-yellow-font: #7D3300;
  --dashboard-yellow-background: #FDE3D1;
  --dashboard-red-font: #7D0000;
  --dashboard-red-background: #FDD4D1;

  --dash-sidebar-primary: #464652;
  --dash-sidebar-secondary: #120638;
  --dash-sidebar-tertiary: #376499;
  --dash-green-color: #E4FDD1;
  --dash-green-dark-color: #8AA377;

  --new-dashboard-primary: #334588;
  --new-dashboard-secondary: #120638;
  --new-dashboard-gray: #666;
  --new-dashboard-gray-light: #CDCDCD;
  --new-dashboard-green: #08B984;
  --new-dashboard-lilac: #7300CF;
  --new-dashboard-blue: #03B4DB;
  --new-dashboard-orange: #FF5C00;
  --new-dashboard-yellow: #FF9900;
  --new-dashboard-red: #DF0000;
  --new-dashboard-gray-dark: #333333;

  --new-dashboard-primary-font: 'Inter', sans-serif;

  --size-xxl: 60px;
  --size-xl: 50px;
  --size-large: 38px;
  --size-medium: 22px;
  --size-small: 14px;
}

* {
  font-family: var(--primary-font);
}
